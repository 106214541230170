import * as _xmlhttprequestSsl2 from "xmlhttprequest-ssl";

var _xmlhttprequestSsl = "default" in _xmlhttprequestSsl2 ? _xmlhttprequestSsl2.default : _xmlhttprequestSsl2;

import _pollingXhr from "./polling-xhr";
import _pollingJsonp from "./polling-jsonp";
import _websocket from "./websocket";
var exports = {};

/**
 * Module dependencies
 */
var XMLHttpRequest = _xmlhttprequestSsl;
var XHR = _pollingXhr;
var JSONP = _pollingJsonp;
var websocket = _websocket;
/**
 * Export transports.
 */

exports.polling = polling;
exports.websocket = websocket;
/**
 * Polling transport polymorphic constructor.
 * Decides on xhr vs jsonp based on feature detection.
 *
 * @api private
 */

function polling(opts) {
  var xhr;
  var xd = false;
  var xs = false;
  var jsonp = false !== opts.jsonp;

  if (typeof location !== "undefined") {
    var isSSL = "https:" === location.protocol;
    var port = location.port; // some user agents have empty `location.port`

    if (!port) {
      port = isSSL ? 443 : 80;
    }

    xd = opts.hostname !== location.hostname || port !== opts.port;
    xs = opts.secure !== isSSL;
  }

  opts.xdomain = xd;
  opts.xscheme = xs;
  xhr = new XMLHttpRequest(opts);

  if ("open" in xhr && !opts.forceJSONP) {
    return new XHR(opts);
  } else {
    if (!jsonp) throw new Error("JSONP disabled");
    return new JSONP(opts);
  }
}

export default exports;