import _index from "./transports/index";
import * as _componentEmitter2 from "component-emitter";

var _componentEmitter = "default" in _componentEmitter2 ? _componentEmitter2.default : _componentEmitter2;

import * as _debug2 from "debug";

var _debug = "default" in _debug2 ? _debug2.default : _debug2;

import * as _indexof2 from "indexof";

var _indexof = "default" in _indexof2 ? _indexof2.default : _indexof2;

import * as _engine2 from "engine.io-parser";

var _engine = "default" in _engine2 ? _engine2.default : _engine2;

import * as _parseuri2 from "parseuri";

var _parseuri = "default" in _parseuri2 ? _parseuri2.default : _parseuri2;

import * as _parseqs2 from "parseqs";

var _parseqs = "default" in _parseqs2 ? _parseqs2.default : _parseqs2;

import _transport from "./transport";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Module dependencies.
 */
var transports = _index;
var Emitter = _componentEmitter;

var debug = _debug("engine.io-client:socket");

var index = _indexof;
var parser = _engine;
var parseuri = _parseuri;
var parseqs = _parseqs;
/**
 * Module exports.
 */

exports = Socket;
/**
 * Socket constructor.
 *
 * @param {String|Object} uri or options
 * @param {Object} options
 * @api public
 */

function Socket(uri, opts) {
  if (!((this || _global) instanceof Socket)) return new Socket(uri, opts);
  opts = opts || {};

  if (uri && "object" === typeof uri) {
    opts = uri;
    uri = null;
  }

  if (uri) {
    uri = parseuri(uri);
    opts.hostname = uri.host;
    opts.secure = uri.protocol === "https" || uri.protocol === "wss";
    opts.port = uri.port;
    if (uri.query) opts.query = uri.query;
  } else if (opts.host) {
    opts.hostname = parseuri(opts.host).host;
  }

  (this || _global).secure = null != opts.secure ? opts.secure : typeof location !== "undefined" && "https:" === location.protocol;

  if (opts.hostname && !opts.port) {
    // if no port is specified manually, use the protocol default
    opts.port = (this || _global).secure ? "443" : "80";
  }

  (this || _global).agent = opts.agent || false;
  (this || _global).hostname = opts.hostname || (typeof location !== "undefined" ? location.hostname : "localhost");
  (this || _global).port = opts.port || (typeof location !== "undefined" && location.port ? location.port : (this || _global).secure ? 443 : 80);
  (this || _global).query = opts.query || {};
  if ("string" === typeof (this || _global).query) (this || _global).query = parseqs.decode((this || _global).query);
  (this || _global).upgrade = false !== opts.upgrade;
  (this || _global).path = (opts.path || "/engine.io").replace(/\/$/, "") + "/";
  (this || _global).forceJSONP = !!opts.forceJSONP;
  (this || _global).jsonp = false !== opts.jsonp;
  (this || _global).forceBase64 = !!opts.forceBase64;
  (this || _global).enablesXDR = !!opts.enablesXDR;
  (this || _global).timestampParam = opts.timestampParam || "t";
  (this || _global).timestampRequests = opts.timestampRequests;
  (this || _global).transports = opts.transports || ["polling", "websocket"];
  (this || _global).transportOptions = opts.transportOptions || {};
  (this || _global).readyState = "";
  (this || _global).writeBuffer = [];
  (this || _global).prevBufferLen = 0;
  (this || _global).policyPort = opts.policyPort || 843;
  (this || _global).rememberUpgrade = opts.rememberUpgrade || false;
  (this || _global).binaryType = null;
  (this || _global).onlyBinaryUpgrades = opts.onlyBinaryUpgrades;
  (this || _global).perMessageDeflate = false !== opts.perMessageDeflate ? opts.perMessageDeflate || {} : false;
  if (true === (this || _global).perMessageDeflate) (this || _global).perMessageDeflate = {};

  if ((this || _global).perMessageDeflate && null == (this || _global).perMessageDeflate.threshold) {
    (this || _global).perMessageDeflate.threshold = 1024;
  } // SSL options for Node.js client


  (this || _global).pfx = opts.pfx || null;
  (this || _global).key = opts.key || null;
  (this || _global).passphrase = opts.passphrase || null;
  (this || _global).cert = opts.cert || null;
  (this || _global).ca = opts.ca || null;
  (this || _global).ciphers = opts.ciphers || null;
  (this || _global).rejectUnauthorized = opts.rejectUnauthorized === undefined ? true : opts.rejectUnauthorized;
  (this || _global).forceNode = !!opts.forceNode; // detect ReactNative environment

  (this || _global).isReactNative = typeof navigator !== "undefined" && typeof navigator.product === "string" && navigator.product.toLowerCase() === "reactnative"; // other options for Node.js or ReactNative client

  if (typeof self === "undefined" || (this || _global).isReactNative) {
    if (opts.extraHeaders && Object.keys(opts.extraHeaders).length > 0) {
      (this || _global).extraHeaders = opts.extraHeaders;
    }

    if (opts.localAddress) {
      (this || _global).localAddress = opts.localAddress;
    }
  } // set on handshake


  (this || _global).id = null;
  (this || _global).upgrades = null;
  (this || _global).pingInterval = null;
  (this || _global).pingTimeout = null; // set on heartbeat

  (this || _global).pingIntervalTimer = null;
  (this || _global).pingTimeoutTimer = null;
  this.open();
}

Socket.priorWebsocketSuccess = false;
/**
 * Mix in `Emitter`.
 */

Emitter(Socket.prototype);
/**
 * Protocol version.
 *
 * @api public
 */

Socket.protocol = parser.protocol; // this is an int

/**
 * Expose deps for legacy compatibility
 * and standalone browser access.
 */

Socket.Socket = Socket;
Socket.Transport = _transport;
Socket.transports = _index;
Socket.parser = _engine;
/**
 * Creates transport of the given type.
 *
 * @param {String} transport name
 * @return {Transport}
 * @api private
 */

Socket.prototype.createTransport = function (name) {
  debug("creating transport \"%s\"", name);
  var query = clone((this || _global).query); // append engine.io protocol identifier

  query.EIO = parser.protocol; // transport name

  query.transport = name; // per-transport options

  var options = (this || _global).transportOptions[name] || {}; // session id if we already have one

  if ((this || _global).id) query.sid = (this || _global).id;
  var transport = new transports[name]({
    query: query,
    socket: this || _global,
    agent: options.agent || (this || _global).agent,
    hostname: options.hostname || (this || _global).hostname,
    port: options.port || (this || _global).port,
    secure: options.secure || (this || _global).secure,
    path: options.path || (this || _global).path,
    forceJSONP: options.forceJSONP || (this || _global).forceJSONP,
    jsonp: options.jsonp || (this || _global).jsonp,
    forceBase64: options.forceBase64 || (this || _global).forceBase64,
    enablesXDR: options.enablesXDR || (this || _global).enablesXDR,
    timestampRequests: options.timestampRequests || (this || _global).timestampRequests,
    timestampParam: options.timestampParam || (this || _global).timestampParam,
    policyPort: options.policyPort || (this || _global).policyPort,
    pfx: options.pfx || (this || _global).pfx,
    key: options.key || (this || _global).key,
    passphrase: options.passphrase || (this || _global).passphrase,
    cert: options.cert || (this || _global).cert,
    ca: options.ca || (this || _global).ca,
    ciphers: options.ciphers || (this || _global).ciphers,
    rejectUnauthorized: options.rejectUnauthorized || (this || _global).rejectUnauthorized,
    perMessageDeflate: options.perMessageDeflate || (this || _global).perMessageDeflate,
    extraHeaders: options.extraHeaders || (this || _global).extraHeaders,
    forceNode: options.forceNode || (this || _global).forceNode,
    localAddress: options.localAddress || (this || _global).localAddress,
    requestTimeout: options.requestTimeout || (this || _global).requestTimeout,
    protocols: options.protocols || void 0,
    isReactNative: (this || _global).isReactNative
  });
  return transport;
};

function clone(obj) {
  var o = {};

  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      o[i] = obj[i];
    }
  }

  return o;
}
/**
 * Initializes transport to use and starts probe.
 *
 * @api private
 */


Socket.prototype.open = function () {
  var transport;

  if ((this || _global).rememberUpgrade && Socket.priorWebsocketSuccess && (this || _global).transports.indexOf("websocket") !== -1) {
    transport = "websocket";
  } else if (0 === (this || _global).transports.length) {
    // Emit error on next tick so it can be listened to
    var self = this || _global;
    setTimeout(function () {
      self.emit("error", "No transports available");
    }, 0);
    return;
  } else {
    transport = (this || _global).transports[0];
  }

  (this || _global).readyState = "opening"; // Retry with the next transport if the transport is disabled (jsonp: false)

  try {
    transport = this.createTransport(transport);
  } catch (e) {
    (this || _global).transports.shift();

    this.open();
    return;
  }

  transport.open();
  this.setTransport(transport);
};
/**
 * Sets the current transport. Disables the existing one (if any).
 *
 * @api private
 */


Socket.prototype.setTransport = function (transport) {
  debug("setting transport %s", transport.name);
  var self = this || _global;

  if ((this || _global).transport) {
    debug("clearing existing transport %s", (this || _global).transport.name);

    (this || _global).transport.removeAllListeners();
  } // set up transport


  (this || _global).transport = transport; // set up transport listeners

  transport.on("drain", function () {
    self.onDrain();
  }).on("packet", function (packet) {
    self.onPacket(packet);
  }).on("error", function (e) {
    self.onError(e);
  }).on("close", function () {
    self.onClose("transport close");
  });
};
/**
 * Probes a transport.
 *
 * @param {String} transport name
 * @api private
 */


Socket.prototype.probe = function (name) {
  debug("probing transport \"%s\"", name);
  var transport = this.createTransport(name, {
    probe: 1
  });
  var failed = false;
  var self = this || _global;
  Socket.priorWebsocketSuccess = false;

  function onTransportOpen() {
    if (self.onlyBinaryUpgrades) {
      var upgradeLosesBinary = !(this || _global).supportsBinary && self.transport.supportsBinary;
      failed = failed || upgradeLosesBinary;
    }

    if (failed) return;
    debug("probe transport \"%s\" opened", name);
    transport.send([{
      type: "ping",
      data: "probe"
    }]);
    transport.once("packet", function (msg) {
      if (failed) return;

      if ("pong" === msg.type && "probe" === msg.data) {
        debug("probe transport \"%s\" pong", name);
        self.upgrading = true;
        self.emit("upgrading", transport);
        if (!transport) return;
        Socket.priorWebsocketSuccess = "websocket" === transport.name;
        debug("pausing current transport \"%s\"", self.transport.name);
        self.transport.pause(function () {
          if (failed) return;
          if ("closed" === self.readyState) return;
          debug("changing transport and sending upgrade packet");
          cleanup();
          self.setTransport(transport);
          transport.send([{
            type: "upgrade"
          }]);
          self.emit("upgrade", transport);
          transport = null;
          self.upgrading = false;
          self.flush();
        });
      } else {
        debug("probe transport \"%s\" failed", name);
        var err = new Error("probe error");
        err.transport = transport.name;
        self.emit("upgradeError", err);
      }
    });
  }

  function freezeTransport() {
    if (failed) return; // Any callback called by transport should be ignored since now

    failed = true;
    cleanup();
    transport.close();
    transport = null;
  } // Handle any error that happens while probing


  function onerror(err) {
    var error = new Error("probe error: " + err);
    error.transport = transport.name;
    freezeTransport();
    debug("probe transport \"%s\" failed because of error: %s", name, err);
    self.emit("upgradeError", error);
  }

  function onTransportClose() {
    onerror("transport closed");
  } // When the socket is closed while we're probing


  function onclose() {
    onerror("socket closed");
  } // When the socket is upgraded while we're probing


  function onupgrade(to) {
    if (transport && to.name !== transport.name) {
      debug("\"%s\" works - aborting \"%s\"", to.name, transport.name);
      freezeTransport();
    }
  } // Remove all listeners on the transport and on self


  function cleanup() {
    transport.removeListener("open", onTransportOpen);
    transport.removeListener("error", onerror);
    transport.removeListener("close", onTransportClose);
    self.removeListener("close", onclose);
    self.removeListener("upgrading", onupgrade);
  }

  transport.once("open", onTransportOpen);
  transport.once("error", onerror);
  transport.once("close", onTransportClose);
  this.once("close", onclose);
  this.once("upgrading", onupgrade);
  transport.open();
};
/**
 * Called when connection is deemed open.
 *
 * @api public
 */


Socket.prototype.onOpen = function () {
  debug("socket open");
  (this || _global).readyState = "open";
  Socket.priorWebsocketSuccess = "websocket" === (this || _global).transport.name;
  this.emit("open");
  this.flush(); // we check for `readyState` in case an `open`
  // listener already closed the socket

  if ("open" === (this || _global).readyState && (this || _global).upgrade && (this || _global).transport.pause) {
    debug("starting upgrade probes");

    for (var i = 0, l = (this || _global).upgrades.length; i < l; i++) {
      this.probe((this || _global).upgrades[i]);
    }
  }
};
/**
 * Handles a packet.
 *
 * @api private
 */


Socket.prototype.onPacket = function (packet) {
  if ("opening" === (this || _global).readyState || "open" === (this || _global).readyState || "closing" === (this || _global).readyState) {
    debug("socket receive: type \"%s\", data \"%s\"", packet.type, packet.data);
    this.emit("packet", packet); // Socket is live - any packet counts

    this.emit("heartbeat");

    switch (packet.type) {
      case "open":
        this.onHandshake(JSON.parse(packet.data));
        break;

      case "pong":
        this.setPing();
        this.emit("pong");
        break;

      case "error":
        var err = new Error("server error");
        err.code = packet.data;
        this.onError(err);
        break;

      case "message":
        this.emit("data", packet.data);
        this.emit("message", packet.data);
        break;
    }
  } else {
    debug("packet received with socket readyState \"%s\"", (this || _global).readyState);
  }
};
/**
 * Called upon handshake completion.
 *
 * @param {Object} handshake obj
 * @api private
 */


Socket.prototype.onHandshake = function (data) {
  this.emit("handshake", data);
  (this || _global).id = data.sid;
  (this || _global).transport.query.sid = data.sid;
  (this || _global).upgrades = this.filterUpgrades(data.upgrades);
  (this || _global).pingInterval = data.pingInterval;
  (this || _global).pingTimeout = data.pingTimeout;
  this.onOpen(); // In case open handler closes socket

  if ("closed" === (this || _global).readyState) return;
  this.setPing(); // Prolong liveness of socket on heartbeat

  this.removeListener("heartbeat", (this || _global).onHeartbeat);
  this.on("heartbeat", (this || _global).onHeartbeat);
};
/**
 * Resets ping timeout.
 *
 * @api private
 */


Socket.prototype.onHeartbeat = function (timeout) {
  clearTimeout((this || _global).pingTimeoutTimer);
  var self = this || _global;
  self.pingTimeoutTimer = setTimeout(function () {
    if ("closed" === self.readyState) return;
    self.onClose("ping timeout");
  }, timeout || self.pingInterval + self.pingTimeout);
};
/**
 * Pings server every `this.pingInterval` and expects response
 * within `this.pingTimeout` or closes connection.
 *
 * @api private
 */


Socket.prototype.setPing = function () {
  var self = this || _global;
  clearTimeout(self.pingIntervalTimer);
  self.pingIntervalTimer = setTimeout(function () {
    debug("writing ping packet - expecting pong within %sms", self.pingTimeout);
    self.ping();
    self.onHeartbeat(self.pingTimeout);
  }, self.pingInterval);
};
/**
* Sends a ping packet.
*
* @api private
*/


Socket.prototype.ping = function () {
  var self = this || _global;
  this.sendPacket("ping", function () {
    self.emit("ping");
  });
};
/**
 * Called on `drain` event
 *
 * @api private
 */


Socket.prototype.onDrain = function () {
  (this || _global).writeBuffer.splice(0, (this || _global).prevBufferLen); // setting prevBufferLen = 0 is very important
  // for example, when upgrading, upgrade packet is sent over,
  // and a nonzero prevBufferLen could cause problems on `drain`


  (this || _global).prevBufferLen = 0;

  if (0 === (this || _global).writeBuffer.length) {
    this.emit("drain");
  } else {
    this.flush();
  }
};
/**
 * Flush write buffers.
 *
 * @api private
 */


Socket.prototype.flush = function () {
  if ("closed" !== (this || _global).readyState && (this || _global).transport.writable && !(this || _global).upgrading && (this || _global).writeBuffer.length) {
    debug("flushing %d packets in socket", (this || _global).writeBuffer.length);

    (this || _global).transport.send((this || _global).writeBuffer); // keep track of current length of writeBuffer
    // splice writeBuffer and callbackBuffer on `drain`


    (this || _global).prevBufferLen = (this || _global).writeBuffer.length;
    this.emit("flush");
  }
};
/**
 * Sends a message.
 *
 * @param {String} message.
 * @param {Function} callback function.
 * @param {Object} options.
 * @return {Socket} for chaining.
 * @api public
 */


Socket.prototype.write = Socket.prototype.send = function (msg, options, fn) {
  this.sendPacket("message", msg, options, fn);
  return this || _global;
};
/**
 * Sends a packet.
 *
 * @param {String} packet type.
 * @param {String} data.
 * @param {Object} options.
 * @param {Function} callback function.
 * @api private
 */


Socket.prototype.sendPacket = function (type, data, options, fn) {
  if ("function" === typeof data) {
    fn = data;
    data = undefined;
  }

  if ("function" === typeof options) {
    fn = options;
    options = null;
  }

  if ("closing" === (this || _global).readyState || "closed" === (this || _global).readyState) {
    return;
  }

  options = options || {};
  options.compress = false !== options.compress;
  var packet = {
    type: type,
    data: data,
    options: options
  };
  this.emit("packetCreate", packet);

  (this || _global).writeBuffer.push(packet);

  if (fn) this.once("flush", fn);
  this.flush();
};
/**
 * Closes the connection.
 *
 * @api private
 */


Socket.prototype.close = function () {
  if ("opening" === (this || _global).readyState || "open" === (this || _global).readyState) {
    (this || _global).readyState = "closing";
    var self = this || _global;

    if ((this || _global).writeBuffer.length) {
      this.once("drain", function () {
        if ((this || _global).upgrading) {
          waitForUpgrade();
        } else {
          close();
        }
      });
    } else if ((this || _global).upgrading) {
      waitForUpgrade();
    } else {
      close();
    }
  }

  function close() {
    self.onClose("forced close");
    debug("socket closing - telling transport to close");
    self.transport.close();
  }

  function cleanupAndClose() {
    self.removeListener("upgrade", cleanupAndClose);
    self.removeListener("upgradeError", cleanupAndClose);
    close();
  }

  function waitForUpgrade() {
    // wait for upgrade to finish since we can't send packets while pausing a transport
    self.once("upgrade", cleanupAndClose);
    self.once("upgradeError", cleanupAndClose);
  }

  return this || _global;
};
/**
 * Called upon transport error
 *
 * @api private
 */


Socket.prototype.onError = function (err) {
  debug("socket error %j", err);
  Socket.priorWebsocketSuccess = false;
  this.emit("error", err);
  this.onClose("transport error", err);
};
/**
 * Called upon transport close.
 *
 * @api private
 */


Socket.prototype.onClose = function (reason, desc) {
  if ("opening" === (this || _global).readyState || "open" === (this || _global).readyState || "closing" === (this || _global).readyState) {
    debug("socket close with reason: \"%s\"", reason);
    var self = this || _global; // clear timers

    clearTimeout((this || _global).pingIntervalTimer);
    clearTimeout((this || _global).pingTimeoutTimer); // stop event from firing again for transport

    (this || _global).transport.removeAllListeners("close"); // ensure transport won't stay open


    (this || _global).transport.close(); // ignore further transport communication


    (this || _global).transport.removeAllListeners(); // set ready state


    (this || _global).readyState = "closed"; // clear session id

    (this || _global).id = null; // emit close event

    this.emit("close", reason, desc); // clean buffers after, so users can still
    // grab the buffers on `close` event

    self.writeBuffer = [];
    self.prevBufferLen = 0;
  }
};
/**
 * Filters upgrades, returning only those matching client transports.
 *
 * @param {Array} server upgrades
 * @api private
 *
 */


Socket.prototype.filterUpgrades = function (upgrades) {
  var filteredUpgrades = [];

  for (var i = 0, j = upgrades.length; i < j; i++) {
    if (~index((this || _global).transports, upgrades[i])) filteredUpgrades.push(upgrades[i]);
  }

  return filteredUpgrades;
};

export default exports;