import _socket from "./socket";
import * as _engine2 from "engine.io-parser";

var _engine = "default" in _engine2 ? _engine2.default : _engine2;

var exports = {};
exports = _socket;
/**
 * Exports parser
 *
 * @api public
 *
 */

exports.parser = _engine;
export default exports;
export const parser = exports.parser;