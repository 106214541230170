import _transport from "../transport";
import * as _engine2 from "engine.io-parser";

var _engine = "default" in _engine2 ? _engine2.default : _engine2;

import * as _parseqs2 from "parseqs";

var _parseqs = "default" in _parseqs2 ? _parseqs2.default : _parseqs2;

import * as _componentInherit2 from "component-inherit";

var _componentInherit = "default" in _componentInherit2 ? _componentInherit2.default : _componentInherit2;

import * as _yeast2 from "yeast";

var _yeast = "default" in _yeast2 ? _yeast2.default : _yeast2;

import * as _debug2 from "debug";

var _debug = "default" in _debug2 ? _debug2.default : _debug2;

import * as _ws2 from "ws";

var _ws = "default" in _ws2 ? _ws2.default : _ws2;

import _buffer from "buffer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Buffer = _buffer.Buffer;

/**
 * Module dependencies.
 */
var Transport = _transport;
var parser = _engine;
var parseqs = _parseqs;
var inherit = _componentInherit;
var yeast = _yeast;

var debug = _debug("engine.io-client:websocket");

var BrowserWebSocket, NodeWebSocket;

if (typeof WebSocket !== "undefined") {
  BrowserWebSocket = WebSocket;
} else if (typeof self !== "undefined") {
  BrowserWebSocket = self.WebSocket || self.MozWebSocket;
} else {
  try {
    NodeWebSocket = _ws;
  } catch (e) {}
}
/**
 * Get either the `WebSocket` or `MozWebSocket` globals
 * in the browser or try to resolve WebSocket-compatible
 * interface exposed by `ws` for Node-like environment.
 */


var WebSocketImpl = BrowserWebSocket || NodeWebSocket;
/**
 * Module exports.
 */

exports = WS;
/**
 * WebSocket transport constructor.
 *
 * @api {Object} connection options
 * @api public
 */

function WS(opts) {
  var forceBase64 = opts && opts.forceBase64;

  if (forceBase64) {
    (this || _global).supportsBinary = false;
  }

  (this || _global).perMessageDeflate = opts.perMessageDeflate;
  (this || _global).usingBrowserWebSocket = BrowserWebSocket && !opts.forceNode;
  (this || _global).protocols = opts.protocols;

  if (!(this || _global).usingBrowserWebSocket) {
    WebSocketImpl = NodeWebSocket;
  }

  Transport.call(this || _global, opts);
}
/**
 * Inherits from Transport.
 */


inherit(WS, Transport);
/**
 * Transport name.
 *
 * @api public
 */

WS.prototype.name = "websocket";
/*
 * WebSockets support binary
 */

WS.prototype.supportsBinary = true;
/**
 * Opens socket.
 *
 * @api private
 */

WS.prototype.doOpen = function () {
  if (!this.check()) {
    // let probe timeout
    return;
  }

  var uri = this.uri();
  var protocols = (this || _global).protocols;
  var opts = {
    agent: (this || _global).agent,
    perMessageDeflate: (this || _global).perMessageDeflate
  }; // SSL options for Node.js client

  opts.pfx = (this || _global).pfx;
  opts.key = (this || _global).key;
  opts.passphrase = (this || _global).passphrase;
  opts.cert = (this || _global).cert;
  opts.ca = (this || _global).ca;
  opts.ciphers = (this || _global).ciphers;
  opts.rejectUnauthorized = (this || _global).rejectUnauthorized;

  if ((this || _global).extraHeaders) {
    opts.headers = (this || _global).extraHeaders;
  }

  if ((this || _global).localAddress) {
    opts.localAddress = (this || _global).localAddress;
  }

  try {
    (this || _global).ws = (this || _global).usingBrowserWebSocket && !(this || _global).isReactNative ? protocols ? new WebSocketImpl(uri, protocols) : new WebSocketImpl(uri) : new WebSocketImpl(uri, protocols, opts);
  } catch (err) {
    return this.emit("error", err);
  }

  if ((this || _global).ws.binaryType === undefined) {
    (this || _global).supportsBinary = false;
  }

  if ((this || _global).ws.supports && (this || _global).ws.supports.binary) {
    (this || _global).supportsBinary = true;
    (this || _global).ws.binaryType = "nodebuffer";
  } else {
    (this || _global).ws.binaryType = "arraybuffer";
  }

  this.addEventListeners();
};
/**
 * Adds event listeners to the socket
 *
 * @api private
 */


WS.prototype.addEventListeners = function () {
  var self = this || _global;

  (this || _global).ws.onopen = function () {
    self.onOpen();
  };

  (this || _global).ws.onclose = function () {
    self.onClose();
  };

  (this || _global).ws.onmessage = function (ev) {
    self.onData(ev.data);
  };

  (this || _global).ws.onerror = function (e) {
    self.onError("websocket error", e);
  };
};
/**
 * Writes data to socket.
 *
 * @param {Array} array of packets.
 * @api private
 */


WS.prototype.write = function (packets) {
  var self = this || _global;
  (this || _global).writable = false; // encodePacket efficient as it uses WS framing
  // no need for encodePayload

  var total = packets.length;

  for (var i = 0, l = total; i < l; i++) {
    (function (packet) {
      parser.encodePacket(packet, self.supportsBinary, function (data) {
        if (!self.usingBrowserWebSocket) {
          // always create a new object (GH-437)
          var opts = {};

          if (packet.options) {
            opts.compress = packet.options.compress;
          }

          if (self.perMessageDeflate) {
            var len = "string" === typeof data ? Buffer.byteLength(data) : data.length;

            if (len < self.perMessageDeflate.threshold) {
              opts.compress = false;
            }
          }
        } // Sometimes the websocket has already been closed but the browser didn't
        // have a chance of informing us about it yet, in that case send will
        // throw an error


        try {
          if (self.usingBrowserWebSocket) {
            // TypeError is thrown when passing the second argument on Safari
            self.ws.send(data);
          } else {
            self.ws.send(data, opts);
          }
        } catch (e) {
          debug("websocket closed before onclose event");
        }

        --total || done();
      });
    })(packets[i]);
  }

  function done() {
    self.emit("flush"); // fake drain
    // defer to next tick to allow Socket to clear writeBuffer

    setTimeout(function () {
      self.writable = true;
      self.emit("drain");
    }, 0);
  }
};
/**
 * Called upon close
 *
 * @api private
 */


WS.prototype.onClose = function () {
  Transport.prototype.onClose.call(this || _global);
};
/**
 * Closes socket.
 *
 * @api private
 */


WS.prototype.doClose = function () {
  if (typeof (this || _global).ws !== "undefined") {
    (this || _global).ws.close();
  }
};
/**
 * Generates uri for connection.
 *
 * @api private
 */


WS.prototype.uri = function () {
  var query = (this || _global).query || {};
  var schema = (this || _global).secure ? "wss" : "ws";
  var port = ""; // avoid port if default for schema

  if ((this || _global).port && ("wss" === schema && Number((this || _global).port) !== 443 || "ws" === schema && Number((this || _global).port) !== 80)) {
    port = ":" + (this || _global).port;
  } // append timestamp to URI


  if ((this || _global).timestampRequests) {
    query[(this || _global).timestampParam] = yeast();
  } // communicate binary support capabilities


  if (!(this || _global).supportsBinary) {
    query.b64 = 1;
  }

  query = parseqs.encode(query); // prepend ? to query

  if (query.length) {
    query = "?" + query;
  }

  var ipv6 = (this || _global).hostname.indexOf(":") !== -1;
  return schema + "://" + (ipv6 ? "[" + (this || _global).hostname + "]" : (this || _global).hostname) + port + (this || _global).path + query;
};
/**
 * Feature detection for WebSocket.
 *
 * @return {Boolean} whether this transport is available.
 * @api public
 */


WS.prototype.check = function () {
  return !!WebSocketImpl && !("__initialize" in WebSocketImpl && (this || _global).name === WS.prototype.name);
};

export default exports;