import _polling from "./polling";
import * as _componentInherit2 from "component-inherit";

var _componentInherit = "default" in _componentInherit2 ? _componentInherit2.default : _componentInherit2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Module requirements.
 */
var Polling = _polling;
var inherit = _componentInherit;
/**
 * Module exports.
 */

exports = JSONPPolling;
/**
 * Cached regular expressions.
 */

var rNewline = /\n/g;
var rEscapedNewline = /\\n/g;
/**
 * Global JSONP callbacks.
 */

var callbacks;
/**
 * Noop.
 */

function empty() {}
/**
 * Until https://github.com/tc39/proposal-global is shipped.
 */


function glob() {
  return typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : typeof _global !== "undefined" ? _global : {};
}
/**
 * JSONP Polling constructor.
 *
 * @param {Object} opts.
 * @api public
 */


function JSONPPolling(opts) {
  Polling.call(this || _global, opts);
  (this || _global).query = (this || _global).query || {}; // define global callbacks array if not present
  // we do this here (lazily) to avoid unneeded global pollution

  if (!callbacks) {
    // we need to consider multiple engines in the same page
    var global = glob();
    callbacks = global.___eio = global.___eio || [];
  } // callback identifier


  (this || _global).index = callbacks.length; // add callback to jsonp global

  var self = this || _global;
  callbacks.push(function (msg) {
    self.onData(msg);
  }); // append to query string

  (this || _global).query.j = (this || _global).index; // prevent spurious errors from being emitted when the window is unloaded

  if (typeof addEventListener === "function") {
    addEventListener("beforeunload", function () {
      if (self.script) self.script.onerror = empty;
    }, false);
  }
}
/**
 * Inherits from Polling.
 */


inherit(JSONPPolling, Polling);
/*
 * JSONP only supports binary as base64 encoded strings
 */

JSONPPolling.prototype.supportsBinary = false;
/**
 * Closes the socket.
 *
 * @api private
 */

JSONPPolling.prototype.doClose = function () {
  if ((this || _global).script) {
    (this || _global).script.parentNode.removeChild((this || _global).script);

    (this || _global).script = null;
  }

  if ((this || _global).form) {
    (this || _global).form.parentNode.removeChild((this || _global).form);

    (this || _global).form = null;
    (this || _global).iframe = null;
  }

  Polling.prototype.doClose.call(this || _global);
};
/**
 * Starts a poll cycle.
 *
 * @api private
 */


JSONPPolling.prototype.doPoll = function () {
  var self = this || _global;
  var script = document.createElement("script");

  if ((this || _global).script) {
    (this || _global).script.parentNode.removeChild((this || _global).script);

    (this || _global).script = null;
  }

  script.async = true;
  script.src = this.uri();

  script.onerror = function (e) {
    self.onError("jsonp poll error", e);
  };

  var insertAt = document.getElementsByTagName("script")[0];

  if (insertAt) {
    insertAt.parentNode.insertBefore(script, insertAt);
  } else {
    (document.head || document.body).appendChild(script);
  }

  (this || _global).script = script;
  var isUAgecko = "undefined" !== typeof navigator && /gecko/i.test(navigator.userAgent);

  if (isUAgecko) {
    setTimeout(function () {
      var iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      document.body.removeChild(iframe);
    }, 100);
  }
};
/**
 * Writes with a hidden iframe.
 *
 * @param {String} data to send
 * @param {Function} called upon flush.
 * @api private
 */


JSONPPolling.prototype.doWrite = function (data, fn) {
  var self = this || _global;

  if (!(this || _global).form) {
    var form = document.createElement("form");
    var area = document.createElement("textarea");
    var id = (this || _global).iframeId = "eio_iframe_" + (this || _global).index;
    var iframe;
    form.className = "socketio";
    form.style.position = "absolute";
    form.style.top = "-1000px";
    form.style.left = "-1000px";
    form.target = id;
    form.method = "POST";
    form.setAttribute("accept-charset", "utf-8");
    area.name = "d";
    form.appendChild(area);
    document.body.appendChild(form);
    (this || _global).form = form;
    (this || _global).area = area;
  }

  (this || _global).form.action = this.uri();

  function complete() {
    initIframe();
    fn();
  }

  function initIframe() {
    if (self.iframe) {
      try {
        self.form.removeChild(self.iframe);
      } catch (e) {
        self.onError("jsonp polling iframe removal error", e);
      }
    }

    try {
      // ie6 dynamic iframes with target="" support (thanks Chris Lambacher)
      var html = "<iframe src=\"javascript:0\" name=\"" + self.iframeId + "\">";
      iframe = document.createElement(html);
    } catch (e) {
      iframe = document.createElement("iframe");
      iframe.name = self.iframeId;
      iframe.src = "javascript:0";
    }

    iframe.id = self.iframeId;
    self.form.appendChild(iframe);
    self.iframe = iframe;
  }

  initIframe(); // escape \n to prevent it from being converted into \r\n by some UAs
  // double escaping is required for escaped new lines because unescaping of new lines can be done safely on server-side

  data = data.replace(rEscapedNewline, "\\\n");
  (this || _global).area.value = data.replace(rNewline, "\\n");

  try {
    (this || _global).form.submit();
  } catch (e) {}

  if ((this || _global).iframe.attachEvent) {
    (this || _global).iframe.onreadystatechange = function () {
      if (self.iframe.readyState === "complete") {
        complete();
      }
    };
  } else {
    (this || _global).iframe.onload = complete;
  }
};

export default exports;